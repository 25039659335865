/* global Ten4Slideshow */

// import './vendor/respond.min.js';
import './vendor/ajax.min.js';
import smoothScroll from './vendor/smooth-scroll.min.js';
import './vendor/slider.js';
import requestAnimFrame from './vendor/requestAnimFrame.js';
import './vendor/eventie.min.js';
import './vendor/classie.min.js';
import Player from '@vimeo/player';
import './vendor/slideshow.js';
import './vendor/html5shiv.min.js';

export * from './CookieConsentManager.js';

'use strict';

( function() {
	// Site search
	const site_search = document.getElementById( 'js-site-search-container' );
	const site_search_input = document.getElementById( 'js-site-search-input' );
	const search_toggle = document.getElementById( 'js-site-search-toggle' );
	const search_toggle_mobile = document.getElementById( 'js-site-search-toggle-mobile' );
	const search_close = document.getElementById( 'js-site-search-close' );
	const mobile_nav_toggle = document.getElementById( 'm-nav-toggle' );

	search_toggle.addEventListener( 'click', () => {
		site_search.classList.toggle( 'search-container--open' );
		if ( site_search.classList.contains( 'search-container--open' ) ) {
			site_search_input.focus();
		}
	} );

	search_toggle_mobile.addEventListener( 'click', () => {
		if ( document.body.classList.contains( 'm-nav-open' ) ) {
			toggleMobileNav( mobile_nav_toggle );
		}
		site_search.classList.toggle( 'search-container--open' );
		if ( site_search.classList.contains( 'search-container--open' ) ) {
			site_search_input.focus();
			site_search_input.scrollIntoView();
		}
	} );

	search_close.addEventListener( 'click', () => {
		site_search.classList.toggle( 'search-container--open' );
		if ( site_search.classList.contains( 'search-container--open' ) ) {
			site_search_input.focus();
		}
	} );

	mobile_nav_toggle.addEventListener( 'click', ( e ) => {
		toggleMobileNav( e.target );
	} );

	function toggleMobileNav( toggler ) {
		document.body.classList.toggle( 'm-nav-open' );
		toggler.classList.toggle( 'm-nav-toggle--open' );
	}

	// function popUpWindow( url ) {
	// 	const popup_width = 550;
	// 	const popup_height = 420;
	// 	const x = ( window.screen.width / 2 ) - ( popup_width / 2 );
	// 	const y = ( window.screen.height / 2 ) - ( popup_height / 2 ) - 50;

	// 	const newwindow = window.open( url, '', 'dependent=1,height=' + popup_height.toString() + ',width=' + popup_width.toString() + ',left=' + x.toString() + ',top=' + y.toString() + ',resizable=0,status=0' );

	// 	if ( window.focus ) {
	// 		newwindow.focus();
	// 	}
	// 	return false;
	// }

	// function twitterPopup( tweet_id, action ) {
	// 	const actions = {
	// 		'favorite': 'favorite?tweet_id=',
	// 		'retweet': 'retweet?tweet_id=',
	// 		'reply': 'tweet?in_reply_to='
	// 	};

	// 	const url = 'https://twitter.com/intent/' + actions[action] + tweet_id;

	// 	popUpWindow( url );
	// }

	// function activateShare(){

	// 	const social_share_buttons = document.querySelectorAll( '.social-share' );

	// 	for ( let i = 0; i < social_share_buttons.length; i += 1 ) {
	// 		eventie.bind( social_share_buttons[i], 'click', (event) => {
	// 			event.preventDefault();
	// 			const url = this.getAttribute( 'data-share-url' );
	// 			popUpWindow(url);
	// 		});
	// 	}
	// }

	( () => {

		const sub_nav_toggles = document.querySelectorAll( '.m-nav--second-level__toggle' );

		for ( let i = 0; i < sub_nav_toggles.length; i += 1 ) {
			sub_nav_toggles[i].addEventListener( 'click', ( event ) => {
				event.preventDefault();
				const nav_id = this.getAttribute( 'data-nav-id' );
				document.getElementById( 'sub-nav-' + nav_id ).classList.toggle( 'm-nav--second-level--open' );
			} );
			// eventie.bind( sub_nav_toggles[i], 'click', ( event ) => {
			// 	event.preventDefault();
			// 	const nav_id = this.getAttribute( 'data-nav-id' );
			// 	document.getElementById( 'sub-nav-' + nav_id ).classList.toggle( 'm-nav--second-level--open' )
			// 	// classie.toggleClass( document.getElementById( 'sub-nav-' + nav_id ), 'm-nav--second-level--open' );
			// });
		}
	} )();

	// code from https://github.com/andygrn/Smooth-Scroll/blob/master/smoothscroll.js

	window.smoothScroll = ( function() {
		function createEaseIn() {
			const easing_function = ( p ) => {
				return ( Math.cos( ( p * Math.PI ) + Math.PI ) + 1 ) / 2;
			};

			return function( uneased_percentage ) {
				const limited_uneased_percentage = uneased_percentage < 0 ? 0 : uneased_percentage > 1 ? 1 : uneased_percentage;
				return easing_function( limited_uneased_percentage );
			};
		}

		function scroll( scroll_y_target_pos, duration, end_callback ) {
			const initial_pos = ( window.pageYOffset !== undefined ) ? window.pageYOffset : document.documentElement.scrollTop;
			let scroll_y_pos = initial_pos;
			const difference = Math.abs( scroll_y_pos - scroll_y_target_pos );
			const px_per_ms = Math.round( ( difference / ( duration / 13 ) ) * 1000 ) / 1000;
			const increment = initial_pos < scroll_y_target_pos ? px_per_ms : -px_per_ms;
			const tick = () => {
				scroll_y_pos = scroll_y_pos + increment;
				const approach = initial_pos < scroll_y_target_pos ? Math.abs( scroll_y_pos - initial_pos ) : Math.abs( scroll_y_pos - scroll_y_target_pos );
				window.scrollTo( 0, Math.round( easer( approach / difference ) * difference ) + ( initial_pos < scroll_y_target_pos ? initial_pos : scroll_y_target_pos ) );
				if ( ( increment >= 0 && scroll_y_pos >= scroll_y_target_pos ) || ( increment < 0 && scroll_y_pos <= scroll_y_target_pos ) ) {
					if ( end_callback ) {
						end_callback();
					}
					return;
				}
				requestAnimFrame( tick );
			};
			tick();
		}
		// const timer = null;
		const easer = createEaseIn();
		return {
			scroll: scroll
		};
	} )();

	// code for smooth scroll

	function getOffsetTop( element ) {
		// Thanks to https://github.com/cferdinandi/smooth-scroll
		let offset = 0;
		if ( element.offsetParent ) {
			do {
				offset += element.offsetTop;
				element = element.offsetParent;
			} while ( element );
		}
		offset = Math.max( offset, 0 );
		return offset;
	}

	( () => {
		// Smooth Scroll
		function handleSmoothAnchorClick( anchor ) {
			const target_id = anchor.href.split( '#' )[1];
			const target = document.getElementById( target_id );
			return ( event ) => {
				event.preventDefault();
				const target_y = getOffsetTop( target );
				smoothScroll.scroll( target_y, 1000 );
				const window_width = document.documentElement.clientWidth || document.body.clientWidth;
				if ( window_width <= 480 ) {
					return;
				}
			};
		}
		const anchors = document.querySelectorAll( '.js-smooth-anchor' );
		for ( let i = 0; i < anchors.length; i += 1 ) {
			anchors[i].addEventListener( 'click', handleSmoothAnchorClick( anchors[i] ) );
		}
	} )();

	( () => {
		const iframe = document.querySelector( '#js-vimeo-video' );
		const poster = document.querySelector( '#js-media-poster' );
		if ( !iframe || !poster ) {
			return;
		}
		const player = new Player( iframe );
		function loaded() {
			player.on( 'play', () => {
				poster.classList.add( 'faded' );
			} );
		}
		if ( poster.compare ) {
			loaded();
		} else {
			poster.addEventListener( 'load', loaded );
			poster.addEventListener( 'error', () => {
				return;
			} );
		}
	} )();

	( () => {
		// Toggle handling
		function handleToggle( toggler ) {
			const togglees = document.querySelectorAll( toggler.getAttribute( 'data-togglees' ) );
			return function() {
				for ( let i = 0; i < togglees.length; i += 1 ) {
					if ( togglees[i].classList.contains( 'toggled' ) ) {
						togglees[i].classList.remove( 'toggled' );
						continue;
					}
					togglees[i].classList.add( 'toggled' );
				}
				if ( toggler.classList.contains( 'toggler--a' ) ) {
					toggler.classList.remove( 'toggler--a' );
					toggler.classList.add( 'toggler--b' );
					return;
				}
				toggler.classList.remove( 'toggler--b' );
				toggler.classList.add( 'toggler--a' );
			};
		}
		const togglers = document.querySelectorAll( '.toggler' );
		for ( let i = 0; i < togglers.length; i += 1 ) {
			togglers[i].addEventListener( 'click', handleToggle( togglers[i] ) );
		}
	} )();

	( () => {
		// Height match columns
		function resetElementHeights( elements ) {
			for ( let i = 0; i < elements.length; i += 1 ) {
				elements[i].style.height = 'auto';
			}
		}

		function getShortestElement( elements ) {
			let shortest = elements[0];
			for ( let i = 1; i < elements.length; i += 1 ) {
				if ( elements[i].offsetHeight < shortest.offsetHeight ) {
					shortest = elements[i];
				}
			}
			return shortest;
		}

		const elements = document.querySelectorAll( '.heightmatch' );
		const bins = {};
		let bin_id;

		function heightmatchBin( bin_id ) {
			resetElementHeights( bins[bin_id] );
			const shortest = getShortestElement( bins[bin_id] );
			for ( let i = 0; i < bins[bin_id].length; i += 1 ) {
				bins[bin_id][i].style.height = shortest.offsetHeight + 'px';
			}
		}

		function heightmatchAllBins() {
			for ( const bin_id in bins ) {
				if ( !Object.prototype.hasOwnProperty.call( bins, bin_id ) ) {
					continue;
				}
				heightmatchBin( bin_id );
			}
		}

		for ( let i = 0; i < elements.length; i += 1 ) {
			bin_id = elements[i].getAttribute( 'data-heightmatch-bin' );
			if ( !Object.prototype.hasOwnProperty.call( bins, bin_id ) ) {
				bins[bin_id] = [];
			}
			bins[bin_id].push( elements[i] );
		}
		heightmatchAllBins();
	} )();

	( () => {
		// Modal windows
		function handleModalTrigger( trigger ) {
			const modal = document.getElementById(
				trigger.getAttribute( 'data-modal-id' )
			);
			return function( event ) {
				if ( !modal ) {
					return;
				}
				event.preventDefault();
				modal.classList.add( 'modal--visible' );
			};
		}
		function handleModalClose( modal ) {
			return function( event ) {
				if ( !event.target.classList.contains( 'modal' ) ) {
					// Only close when clicking the background fade.
					return;
				}
				modal.classList.remove( 'modal--visible' );
			};
		}
		const modals = document.querySelectorAll( '.modal' );
		for ( let i = 0; i < modals.length; i += 1 ) {
			modals[i].addEventListener( 'click', handleModalClose( modals[i] ) );
		}
		const triggers = document.querySelectorAll( '.modal-trigger' );
		for ( let i = 0; i < triggers.length; i += 1 ) {
			triggers[i].addEventListener( 'click', handleModalTrigger( triggers[i] ) );
		}
	} )();

	( () => {
		// Slideshow
		const element = document.querySelector( '.slideshow' );
		if ( !element ) {
			return;
		}

		const slideshow = new Ten4Slideshow( element );
		const slide_interval = parseInt( element.getAttribute( 'data-slide-interval' ), 10 );
		const slideshow_pips_container = document.getElementById( 'js-pips-container' );
		const slideshow_pips = slideshow_pips_container ? slideshow_pips_container.querySelectorAll( '.slideshow__pip' ) : null;
		const slideshow_slides = element.querySelectorAll( '.slideshow__slide' );
		let timer_id = null;
		if ( slideshow_pips ) {
			slideshow_pips[0].classList.add( 'slideshow__pip--selected' );
		}
		slideshow_slides[0].classList.add( 'slideshow__slide--animate', 'slideshow__slide--animate-in' );
		setTimeout( () => {
			slideshow_slides[0].classList.remove( 'slideshow__slide--animate-in' );
			slideshow_slides[0].classList.add( 'slideshow__slide--animate-out' );
		}, 4500 );

		slideshow.addCallback( ( index ) => {
			for ( let i = 0; i < slideshow_slides.length; i += 1 ) {
				if ( i === index ) {
					slideshow_slides[i].classList.add( 'slideshow__slide--animate', 'slideshow__slide--animate-in' );
					setTimeout( () => {
						slideshow_slides[i].classList.remove( 'slideshow__slide--animate-in' );
						slideshow_slides[i].classList.add( 'slideshow__slide--animate-out' );
					}, 4500 );
					continue;
				}
				setTimeout( () => {
					slideshow_slides[i].className = 'slideshow__slide';
				}, 500 );
			}
		} );

		if ( slideshow_pips ) {
			slideshow.addCallback( ( index ) => {
				for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
					if ( i === index ) {
						slideshow_pips[i].classList.add( 'slideshow__pip--selected' );
						continue;
					}
					slideshow_pips[i].classList.remove( 'slideshow__pip--selected' );
				}
			} );
		}

		function resetInterval() {
			window.clearInterval( timer_id );
			timer_id = window.setInterval( () => {
				slideshow.slide();
			}, slide_interval );
		}

		function handlePipClick( index ) {
			return () => {
				resetInterval();
				slideshow.slideTo( index );
			};
		}

		if ( slideshow_pips ) {
			for ( let i = 0; i < slideshow_pips.length; i += 1 ) {
				slideshow_pips[i].addEventListener( 'click', handlePipClick( i ) );
			}
		}

		resetInterval();
	} )();

} )();
